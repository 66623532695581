import type React from "react";
import { useEffect } from "react";

function useOnClickOutside(
  ref: React.RefObject<HTMLUListElement | HTMLDivElement>,
  handler: (event: MouseEvent | TouchEvent | KeyboardEvent) => void,
  respectEventPrevented = true,
  closeOnExcape = false,
  ignoreEventsForMS = 0
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // NOTE: event.defaultPrevented is always true when clicking on Draggable elements
      // That's why we can disable that check with respectEventPrevented when needed.

      if (
        !ref.current ||
        ref.current.contains(event.target as Node) ||
        (respectEventPrevented && event.defaultPrevented)
      ) {
        return;
      }

      handler(event);
    };

    setTimeout(() => {
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      document.addEventListener("keydown", (event) => {
        if (closeOnExcape && event.key === "Escape") {
          handler(event);
        }
      });
    }, ignoreEventsForMS);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
      document.removeEventListener("keydown", () => null);
    };
  }, [ref, handler, respectEventPrevented, closeOnExcape, ignoreEventsForMS]);
}

export default useOnClickOutside;
