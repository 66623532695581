import React from "react";
import useOnClickOutside from "./useOnClickOutside";
import { Transition } from "@headlessui/react";
import Button from "./Button";
import Title from "./Title";
import Select from "react-select";
import api from "../../api";
import { Tag } from "../../model";
import { useDataContext } from "../../views/FindLookalike";
import toast from "react-hot-toast";
import ScopeSpinner from "./ScopeSpinner";

type SelectedTag = {
  value: number;
  label: string;
};

function useTagAdd() {
  const { selectedUsers } = useDataContext();

  const [selected, setSelected] = React.useState<SelectedTag>();
  const [tags, setTags] = React.useState<SelectedTag[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    api
      .getScopeTags()
      .then((r) => {
        if (r.data) {
          setTags(
            (r.data as Tag[]).map((t) => ({ value: t.id, label: t.name }))
          );
        }
      })
      .catch((e) => {
        toast.error(e.toString());
      });
  }, []);

  const addToTag = (cb: () => void) => {
    setIsLoading(true);
    if (selected)
      api
        .addToScopeTag(selected.value, selectedUsers)
        .then((r) => {
          console.log(r);
          toast.success("Added to Scope tag");
        })
        .catch((e) => {
          toast.error(e.toString());
        })
        .finally(() => {
          setIsLoading(false);
          cb();
        });
  };
  return {
    selected,
    setSelected,
    tags,
    addToTag,
    isLoading,
  };
}

export default function AddToTagModal({
  closeModal: cb,
}: {
  closeModal: () => void;
}) {
  const { selected, setSelected, tags, addToTag, isLoading } = useTagAdd();

  const [show, setShow] = React.useState(false);
  const [close, setClose] = React.useState(false);

  React.useEffect(() => {
    setShow(true);
    return () => {
      setShow(false);
      setClose(false);
    };
  }, []);

  const closeModal = () => {
    setClose(true);
    setShow(false);
    setTimeout(() => {
      cb();
      setClose(false);
    }, 500);
  };

  const ref = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, closeModal, true, true, 1000);
  return (
    <Transition
      as="div"
      show={!close}
      leave="ease-in-out duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="fixed inset-x-0 bottom-0 z-max size-full animate-fade-in overflow-y-auto bg-black/25"
      aria-hidden
      onClick={(e) => e.stopPropagation()}
    >
      <div className="absolute inset-y-2 left-1/2 -translate-x-2/4 flex w-full items-start justify-center">
        <Transition
          show={show}
          enter="transform ease-in-out duration-500 transition"
          enterFrom="-translate-y-12 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transform ease-in-out duration-500 transition"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="-translate-y-12 opacity-0"
        >
          <div
            ref={ref}
            className="relative inline-block h-fit w-full rounded-md border bg-white pt-6 shadow-lg max-w-screen-lg"
          >
            <div className="mb-6 font-medium lg:px-8">
              <Title as="h3" type="subtitle">
                Add to Tag
              </Title>
            </div>
            <div className="mb-6 flex flex-col gap-2 lg:px-8">
              Select Tag
              <Select
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                value={selected}
                onChange={(v) => setSelected(v || undefined)}
                options={tags}
                className="font-normal"
              />
            </div>
            <div className="flex justify-end gap-4 border-t px-8 py-4">
              <Button onClick={closeModal} label="Cancel" styleType="neutral" />
              <Button
                onClick={() => addToTag(closeModal)}
                label={isLoading ? <ScopeSpinner /> : "Add to Tag"}
                className="w-[100px]"
                styleType={selected ? "primary" : "disabled"}
                disabled={!selected}
              />
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  );
}
