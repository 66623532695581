import cn from "./cn";

function ScopeSpinner({
  grey,
  ...rest
}: JSX.IntrinsicElements["div"] & { grey?: boolean }) {
  return (
    <div className="flex items-center justify-center" {...rest}>
      <div
        className={cn(
          "h-5 w-5 animate-spin rounded-full border-2 border-solid border-indigo-300 border-t-gray-200",
          grey && "border-gray-300 border-t-gray-200"
        )}
      />
    </div>
  );
}

export default ScopeSpinner;
