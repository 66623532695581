import React from "react";
import cn from "./cn";
export type ButtonProps = {
  label: string | JSX.Element;
  styleType:
    | "highlightedPrimary"
    | "primary"
    | "primarySmall"
    | "secondary"
    | "secondarySmall"
    | "neutral"
    | "neutralSmall"
    | "neutralSlim"
    | "disabled"
    | "disabledSmall"
    | "active"
    | "delete"
    | "neutralDisabled"
    | "textButton"
    | "warning";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  additionalClasses?: string;
  disabled?: boolean;
  tagPageHeaderButtons?: boolean;
  icon?: React.ReactNode;
  isResponsive?: boolean;
  extraPadding?: boolean;
};

function responsiveTextClasses(
  isResponsive: boolean,
  tagPageHeaderButtons?: boolean
): string {
  if (tagPageHeaderButtons) return "text-sm px-3";
  return isResponsive ? "text-xs px-3 2xl:text-sm 2xl:px-4" : "text-sm px-4";
}
function getButtonClasses(
  styleType: string,
  isResponsive: boolean,
  tagPageHeaderButtons?: boolean,
  extraPadding?: boolean
): string {
  switch (styleType) {
    case "highlightedPrimary":
      return "inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md focus:outline-none text-base shadow-sm text-white bg-indigo-600 hover:bg-indigo-700";
    case "primary":
      return `inline-flex items-center justify-center py-2 border border-transparent font-medium rounded-md focus:outline-none ${responsiveTextClasses(
        isResponsive
      )} shadow-sm text-white bg-indigo-600 hover:bg-indigo-700`;
    case "primarySmall":
      return "inline-flex items-center justify-center px-3 py-1 border border-transparent font-medium rounded-md focus:outline-none text-sm shadow-sm text-white bg-indigo-600 hover:bg-indigo-700";
    case "secondary":
      return "inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md focus:outline-none text-sm text-indigo-700 bg-indigo-100 hover:bg-indigo-200";
    case "secondarySmall":
      return "inline-flex items-center justify-center px-3 py-1 border border-transparent font-medium rounded-md focus:outline-none text-sm text-indigo-700 bg-indigo-100 hover:bg-indigo-200";
    case "neutral":
      return `inline-flex items-center justify-center py-2 border border-gray-300 font-medium rounded-md focus:outline-none ${responsiveTextClasses(
        isResponsive,
        tagPageHeaderButtons
      )} text-gray-700 bg-white hover:bg-gray-50`;
    case "neutralSmall":
      return `inline-flex items-center justify-center px-3 py-1 border border-gray-300 font-medium rounded-md focus:outline-none ${responsiveTextClasses(
        isResponsive
      )} text-gray-700 bg-white hover:bg-gray-50`;
    case "neutralSlim":
      return `inline-flex items-center justify-center ${
        extraPadding ? "py-2.5 px-3.5" : "px-2 py-2"
      }
      border border-gray-300 font-medium rounded-md focus:outline-none ${responsiveTextClasses(
        isResponsive
      )} text-gray-700 bg-white hover:bg-gray-50`;
    case "neutralDisabled":
      return `inline-flex items-center justify-center py-2 border border-gray-300 font-medium rounded-md focus:outline-none ${responsiveTextClasses(
        isResponsive,
        tagPageHeaderButtons
      )} text-gray-700 bg-white hover:border-gray-400 hover:cursor-not-allowed hover:text-gray-400`;
    case "disabled":
      return "inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-sm text-gray-400 bg-gray-200 cursor-not-allowed";
    case "disabledSmall":
      return "inline-flex items-center justify-center px-3 py-1 border border-transparent font-medium rounded-md text-sm text-gray-400 bg-gray-200 cursor-not-allowed";
    case "active":
      return "inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md outline-none text-white bg-indigo-600 text-sm";
    case "delete":
      return "inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md focus:outline-none text-sm shadow-sm text-white bg-red-600 hover:bg-red-700";
    case "textButton":
      return "inline-flex items-center justify-center rounded-md text-xs text-indigo-600 hover:text-indigo-800";
    case "warning":
      return `inline-flex items-center justify-center py-2 border border-red-200 font-medium rounded-md focus:outline-none ${responsiveTextClasses(
        isResponsive
      )} text-red-700 bg-red-100 hover:bg-red-200`;
    default:
      return "inline-flex items-center justify-center px-6 py-2 border border-transparent font-medium rounded-md focus:outline-none  text-sm shadow-sm text-white bg-indigo-600 hover:bg-indigo-700";
  }
}

function Button({
  label,
  styleType,
  onClick,
  additionalClasses,
  disabled = false,
  isResponsive = false,
  tagPageHeaderButtons = false,
  extraPadding = false,
  icon,
  className,
  ...rest
}: ButtonProps & JSX.IntrinsicElements["button"]) {
  return (
    <button
      className={cn(
        getButtonClasses(
          styleType,
          isResponsive,
          tagPageHeaderButtons,
          extraPadding
        ),
        additionalClasses,
        className
      )}
      type="button"
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {label}
      {icon && icon}
    </button>
  );
}

export default React.memo(Button);
