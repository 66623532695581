import { InformationCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Tooltip } from "react-tooltip";
type InfoIconWithTooltipPropsType = {
  text: string;
  place?: string;
  heightClass?: string;
  topClass?: string;
  position?: "right" | "left";
  zMax?: boolean;
};

function InfoIconWithTooltip({
  text,
  position = "right",
  zMax = false,
}: InfoIconWithTooltipPropsType) {
  return (
    <div
      className="group relative size-5"
      data-tooltip-content={text}
      data-tooltip-id={`notify-tooltip-${text}`}
      data-tooltip-place={position}
      data-tooltip-class-name={zMax ? "!z-max" : ""}
    >
      <Tooltip
        id={`notify-tooltip-${text}`}
        className="group-hover:opacity-100 opacity-0 font-normal text-sm"
      />
      <InformationCircleIcon className="size-full text-gray-400" />
    </div>
  );
}

export default React.memo(InfoIconWithTooltip);
