import React, { useEffect } from "react";
import { useState } from "react";
import {
  EyeIcon,
  EyeSlashIcon,
  Cog8ToothIcon,
} from "@heroicons/react/24/solid";
import { User } from "../model";
import api from "../api";
import { Transition } from "@headlessui/react";
import InfoIconWithTooltip from "./scope/InfoIconWithTooltip";
import { useDataContext } from "../views/FindLookalike";
import { XMarkIcon } from "@heroicons/react/20/solid";

function wasClickInRect(rect: any, x: number, y: number): boolean {
  if (x < rect.left || x >= rect.right) return false;
  if (y < rect.top || y >= rect.bottom) return false;
  return true;
}

interface SettingsSidePanelProps {
  user: User;
  setShouldReceiveEmails: (isEnabled: boolean) => void;
}

function SettingsSidePanel(props: SettingsSidePanelProps) {
  const {
    isSidePanelOpen: isOpen,
    setIsSidePanelOpen: setIsOpen,
    tokenExpired,
  } = useDataContext();
  const { user, setShouldReceiveEmails } = props;

  const toggleOpen = (e: React.MouseEvent) => {
    const panel = document.getElementById("panel");
    const rect = panel?.getBoundingClientRect();
    if (!rect) {
      return setIsOpen(!isOpen);
    }
    const wasInRect = wasClickInRect(rect, e.clientX, e.clientY);
    if (wasInRect) {
      return;
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (tokenExpired) {
      setIsOpen(true);
    }
  }, [setIsOpen, tokenExpired]);

  return (
    <div className={"flex items-center"}>
      <button
        className={"w-full p-1 text-gray-600 hover:bg-gray-100 rounded-full"}
        onClick={toggleOpen}
      >
        <Cog8ToothIcon className={"h-full w-8"} />
      </button>

      {isOpen && (
        <Panel
          toggleOpen={toggleOpen}
          user={user}
          setShouldReceiveEmails={setShouldReceiveEmails}
        />
      )}
    </div>
  );
}

interface PanelProps {
  toggleOpen: (e: React.MouseEvent) => void;
  user: User;
  setShouldReceiveEmails: (isEnabled: boolean) => void;
}

function Panel(props: PanelProps) {
  const { tokenExpired } = useDataContext();

  const { toggleOpen, user, setShouldReceiveEmails } = props;

  const [inputApiKey, setInputApiKey] = useState(user.scopeApiKey || "");
  const [scopeApiKeyHidden, setScopeApiKeyHidden] = useState(true);
  const [show, setShow] = React.useState(false);
  const [close, setClose] = React.useState(true);

  React.useEffect(() => {
    setShow(true);
    setClose(false);
    return () => {
      setShow(false);
      setClose(false);
    };
  }, []);

  React.useEffect(() => {
    if (tokenExpired) {
      setInputApiKey("");
    }
  }, [tokenExpired]);

  const closeModal = (e: React.MouseEvent) => {
    setClose(true);
    setShow(false);
    setTimeout(() => {
      toggleOpen(e);
      setClose(false);
    }, 500);
  };
  return (
    <div
      className="z-10 absolute top-0 right-0 h-full"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div id="backdrop-container">
        <Transition
          show={!close}
          enter="ease-in-out duration-700"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-700"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            id="backdrop"
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            onClick={closeModal}
          >
            {" "}
          </div>
        </Transition>
      </div>
      <Transition
        show={show}
        enter="transform transition ease-in-out duration-700"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-700"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div
          id="panel"
          className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl absolute top-0 right-0 z-10 w-screen sm:w-[400px]"
        >
          <div className="px-4 sm:px-6 pb-4 flex w-full justify-between items-center">
            <h1
              className="text-base font-semibold leading-6 text-gray-900"
              id="slide-over-title"
            >
              Settings
            </h1>
            <XMarkIcon
              className="size-6 cursor-pointer select-none text-gray-400 hover:text-gray-700 duration-200"
              onClick={closeModal}
            />
          </div>
          <div className="relative flex flex-col flex-1 px-4 sm:px-6 gap-5">
            {user.email}
            <div className="flex gap-2 w-full items-center">
              <h2 className="text-base font-semibold text-gray-900">
                Email Notifications
              </h2>
              <InfoIconWithTooltip
                text="Receive Email notification when lookalike job is complete"
                position="right"
              />
              <input
                className="size-4"
                type={"checkbox"}
                id={"shouldReceiveEmails"}
                checked={user.shouldReceiveEmails}
                onClick={() => {
                  setShouldReceiveEmails(!user.shouldReceiveEmails);
                }}
              />
            </div>
            <div>
              <h2 className="text-base font-semibold leading-6 text-gray-900 flex gap-2 items-center">
                Scope Integration
                <InfoIconWithTooltip
                  text="Connect to Scope to add Tiktok profiles to Tags"
                  position="right"
                />
                {tokenExpired && (
                  <div className="px-2 py-1 rounded bg-red-100 text-red-800">
                    Expired
                  </div>
                )}
              </h2>
              <div className={"w-full gap-2"}>
                <span className={"text-xs text-gray-700"}>API Key</span>
                <div className={"flex gap-2"}>
                  <div className={"relative w-full flex gap-2"}>
                    <input
                      id={"scope_api_key"}
                      className={
                        "block w-full rounded-md border border-gray-300 p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-smpr-10 pr-10"
                      }
                      placeholder="Scope API Key"
                      value={inputApiKey}
                      spellCheck={false}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      onChange={(e) => setInputApiKey(e.target.value)}
                      type={scopeApiKeyHidden ? "password" : "text"}
                    />
                    <div
                      className={
                        "absolute right-2.5 top-2.5 h-5 cursor-pointer select-none  text-gray-400 hover:text-gray-700"
                      }
                      onClick={() => setScopeApiKeyHidden(!scopeApiKeyHidden)}
                    >
                      {!scopeApiKeyHidden ? (
                        <EyeIcon className={"w-5"} />
                      ) : (
                        <EyeSlashIcon className={"w-5"} />
                      )}
                    </div>
                  </div>
                  <button
                    className={
                      "inline-flex items-center justify-center py-2 border border-gray-300 font-medium rounded-md focus:outline-none text-sm px-4 text-gray-700 bg-white hover:bg-gray-50 min-w-max"
                    }
                    onClick={() => {
                      api.setScopeApiKey(inputApiKey);
                      if (user.scopeApiKey !== inputApiKey)
                        window.location.reload();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default SettingsSidePanel;
