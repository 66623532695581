import axios from 'axios';


function getUserInfo() {
    return axios.get('/api/user/info');
}

function getIsLoggedIn() {
    return axios.get('/api/is_logged_in');
}

function userSubscribe(plan: string, signal?: AbortSignal) {
    return axios.get(`/api/customer-subscribe?planAmount=${plan}`, { signal });
}

function setShouldReceiveEmails(shouldReceiveEmails: boolean) {
    return axios.post('/api/user/shouldReceiveEmails', { shouldReceiveEmails });
}

function startLookalikeJob(usernames: string[]) {
    return axios.post('/api/startLookALikeJob', { usernames });
}

function setScopeApiKey(apiKey: string) {
    return axios.post('/api/user/set_scope_api_key', { api_key: apiKey });
}

function getScopeTags() {
    return axios.get('/api/scope/get_tags');
}

function addToScopeTag(tagId: number, tiktokIds: number[]) {
    return axios.post('/api/scope/add_to_tag', { tag_id: tagId, tiktok_ids: tiktokIds });
}


const apis = {
    getUserInfo,
    getIsLoggedIn,
    userSubscribe,
    setShouldReceiveEmails,
    setScopeApiKey,
    getScopeTags,
    addToScopeTag,
    startLookalikeJob
}
export default apis
