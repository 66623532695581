import './Spinners.css';

interface TiktokLoadingSpinnerProps {
    width?: number,
    height?: number,
    margin?: number
}
export function TiktokLoadingSpinner({
    width=72, height=72, margin=15
}: TiktokLoadingSpinnerProps) {
    return (
        <div id="tiktok-spinner" style={{
            width: `${width}px`,
            height: `${height}px`,
            margin: `auto auto ${margin}px`
        }}/>
    )
}

export default TiktokLoadingSpinner;
