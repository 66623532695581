import React from "react";
import { Menubar, MenubarMenu } from "./menubar";
import Button from "./Button";
import AddToTagModal from "./Modal";
import { useDataContext } from "../../views/FindLookalike";
import { Transition } from "@headlessui/react";

export default function MultiselectMenu({
  selectionLength,
  profilesLength,
  updateSelection,
  failOver,
}: {
  selectionLength: number;
  profilesLength: number;
  updateSelection: (uid?: number) => void;
  failOver: boolean;
}) {
  const { setIsSidePanelOpen, isSidePanelOpen } = useDataContext();
  const [openModal, setOpenModal] = React.useState(false);
  // some hacks to change ux
  const [dropdownOpen, setDropdownOpen] = React.useState<"selected">();
  React.useEffect(() => {
    if (selectionLength && dropdownOpen !== "selected") {
      setDropdownOpen("selected");
    }
    if (!selectionLength && dropdownOpen === "selected") {
      setDropdownOpen(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionLength]);

  const showDropdown =
    !isSidePanelOpen && !openModal && dropdownOpen === "selected";
  return (
    <div className="text-gray-700">
      <Menubar className="px-0 border-0 shadow-none" value={dropdownOpen}>
        <MenubarMenu value={dropdownOpen}>
          <input
            type="checkbox"
            className="cursor-pointer"
            checked={
              Boolean(profilesLength) && selectionLength === profilesLength
            }
            onChange={() => updateSelection(-1)}
          />
          <Transition
            show={showDropdown}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="fixed bottom-4 left-1/2 -translate-x-1/2 z-50 min-w-[12rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md">
              <p className="w-full p-2 font-normal text-sm">
                {selectionLength} selected
              </p>
              <div className="flex w-48 p-2">
                <Button
                  label="Add to tag"
                  styleType={selectionLength ? "neutral" : "neutralDisabled"}
                  className="w-full"
                  onClick={() => {
                    if (failOver) setIsSidePanelOpen(true);
                    else setOpenModal(true);
                  }}
                  disabled={!selectionLength}
                />
              </div>
            </div>
          </Transition>
        </MenubarMenu>
      </Menubar>
      {openModal && (
        <AddToTagModal
          closeModal={() => {
            setOpenModal(false);
            updateSelection(-2);
          }}
        />
      )}
    </div>
  );
}
