import type React from "react";

export type TitleProps = {
  children: React.ReactNode;
  type?: "title" | "subtitle" | "heading" | "subheading" | "username";
  as?: keyof JSX.IntrinsicElements;
  isResponsive?: boolean;
};
function responsiveHeadingClasses(isResponsive: boolean): string {
  return isResponsive ? "text-lg 2xl:text-xl" : "text-xl";
}

function getTitleClasses(type: string, isResponsive: boolean): string {
  switch (type) {
    case "title":
      return "text-3xl";
    case "subtitle":
      return "text-2xl";
    case "heading":
      return responsiveHeadingClasses(isResponsive);
    case "subheading":
      return "text-lg";
    case "username":
      return "text-md";
    default:
      return "text-3xl";
  }
}

function Title({
  children,
  type = "title",
  as: TitleElement = "h1",
  isResponsive = false,
}: TitleProps) {
  return (
    <TitleElement className={getTitleClasses(type, isResponsive)}>
      {children}
    </TitleElement>
  );
}

export default Title;
