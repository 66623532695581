import { useEffect, useState } from "react";
import { PlanOptions, User } from "../model"
import { capitalizeFirstLetter } from "./helpers";
import api from "../api";

const styles = {
    'active': "bg-indigo-300 py-2 px-4 text-white font-bold rounded-lg cursor-pointer active:scale-[98%] hover:bg-indigo-600 focus:outline-none focus:ring-indigo-500 focus:ring-offset-2",
    'disabled': "bg-gray-300 py-2 px-4 text-white font-bold rounded-lg cursor-not-allowed",
    'select': "bg-indigo-600 py-2 px-4 text-white font-bold rounded-lg cursor-pointer active:scale-[98%] hover:bg-indigo-600 focus:outline-none focus:ring-indigo-500 focus:ring-offset-2"
}

type PlansAndTrial = "trial" | PlanOptions;

const PLANS: Record<string, { PRICE?: string, QUOTA?: string, name: PlansAndTrial }> = {
    Basic: {
        PRICE: "20",
        QUOTA: "10",
        name: "10",
    },
    Pro: {
        PRICE: "50",
        QUOTA: "50",
        name: "50"
    },
    // Add more plans here (respecting sequence)
    //
    // Plan_Name: {...}
    //
    // ---- Above ----
    Enterprise: {
        PRICE: "150",
        name: "unlimited"
    },
    Trial: {
        name: "trial"
    }
} as const

function getState(forPlan: User['activePlan'], activePlan: User['activePlan'], cause: 'trial' | 'limit', chosen?: PlansAndTrial /** Will never be 'Trial' */): [string, string, boolean] {
    const plans = Object.values(PLANS)
    const aid = plans.findIndex(p => p.name === activePlan)
    const pid = plans.findIndex(p => p.name === forPlan)
    if (!chosen) {
        if (cause === PLANS.Trial.name || activePlan === PLANS.Trial.name) return [styles['active'], 'Select', false]
        if (pid <= aid) return [styles['disabled'], 'Exceeded', true]
        return [styles['active'], 'Select', false]
    } else {
        if (cause === 'limit' && pid <= aid) return [styles['disabled'], 'Exceeded', true]
        if (chosen === forPlan) return [styles['select'], 'Selected', false]
        return [styles['active'], 'Select', false]
    }
}

async function onSubscribe(plan: PlansAndTrial, signal: AbortSignal): Promise<string | null> {
    return api.userSubscribe(plan, signal)
        .then(resp => {
            return resp.data as string;
        })
        .catch(e => {
            console.error('userSubscribe error', e);
            throw e.message ?? "Errored.";
        })
}

export default function TrialBlock({ activePlan, cause }: { activePlan: User['activePlan'], cause: 'trial' | 'limit' }) {
    const [choice, setChoice] = useState<{ url: string, plan: PlansAndTrial }>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any>()
    const [ac, setAc] = useState(new AbortController())
    const onClick = (plan: "trial" | PlanOptions) => {
        setLoading(true)
        setChoice({ url: '', plan })
        ac.abort();
        setAc(new AbortController())
        setError(undefined)
    }
    useEffect(() => {
        const plan = choice?.plan
        if (plan)
            onSubscribe(plan, ac.signal).then(url => {
                if (url)
                    setChoice({ url, plan })
                setLoading(false)
            }).catch(console.error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ac])
    useEffect(() => {
        return () => {
            setError(undefined)
            setLoading(false)
        }
    }, [])
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-center sm:justify-center">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                {
                                    cause === 'limit' ? (
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Choose a
                                            new subscription to gain more access</h3>
                                    ) : (
                                        <h3 className="text-lg leading-6 font-medium text-gray-900 items-center flex">Your trial is over
                                            <img alt="sad_smile" className="size-6 mx-1" src="https://em-content.zobj.net/thumbs/240/apple/354/smiling-face-with-tear_1f972.png"
                                            /> Subscribe to gain
                                            access</h3>
                                    )
                                }
                            </div>
                        </div>
                        <div className="mt-5 grid grid-cols-3 gap-5">
                            {Object.entries(PLANS).filter(p => p[0] !== 'Trial').map(data => (
                                <div className="bg-white rounded-lg shadow-md p-5">
                                    <h4 className="text-lg font-medium text-gray-900 mb-4">{data[0]}</h4>
                                    <ul className="text-sm text-gray-500">
                                        <li className="my-1">{capitalizeFirstLetter(data[1].name)} Lookalikes{data[1].name !== 'unlimited' ? " per month" : ""}</li>
                                    </ul>
                                    <div className="text-center py-2 text-lg font-medium">${data[1].PRICE}/month</div>
                                    <button className={getState(data[1].name, activePlan, cause, choice?.plan)[0]}
                                        onClick={() => onClick(data[1].name)}
                                        disabled={getState(data[1].name, activePlan, cause, choice?.plan)[2]}
                                    >
                                        {getState(data[1].name, activePlan, cause, choice?.plan)[1]}
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="w-full flex justify-between items-center mt-4">
                            <h4 className="text-lg font-medium text-gray-900 mb-4 mt-6 flex items-center">
                                <img alt="checkmark"
                                    className="size-8"
                                    src="https://scopeapp.io/images/icon-check.svg"
                                />Plans can be cancelled anytime</h4>
                            <button className={styles[choice?.url ? 'select' : 'disabled']}
                                onClick={() => {
                                    if (choice?.url)
                                        window.open(choice.url, "_self");
                                }}
                                disabled={!choice?.url}
                            >
                                {loading ? "Loading" : "Subscribe"}
                            </button>
                        </div>
                        <p className="text-red-500">
                            {error ? "Error: " + JSON.stringify(error) : null}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
